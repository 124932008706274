.background-layer {
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-size: cover;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-image: url("../../assets/horizontal.png");
}
