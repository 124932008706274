.MuiList-root  {
  flex: 1;
  overflow-y: auto;
  padding-top: 0!important;
}

 .MuiListItemButton-root {
  height: 46px!important;
  padding-left: 21px !important
}

 .MuiListItemButton-root:hover {
  background-color: var(--hovered-background-color) !important;
  border-left: 6px solid var(--base-color)!important;
  padding-left: 15px !important
}

 .MuiListItem-root.Mui-selected {
  padding-left: 0px !important;
  background-color: var(--hovered-background-color) !important
}

 .MuiListItem-root.Mui-selected>div {
  border-left: 6px solid var(--base-color) !important;
  padding-left: 15px !important
}